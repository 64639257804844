var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":""}},[_c('v-toolbar-title',[_c('a',{attrs:{"href":"/"}},[_vm._v("ℤ")])]),_c('v-spacer'),(!_vm.profile)?_c('v-btn',{staticClass:"mr-2",attrs:{"to":"/login","text":""}},[_vm._v("Log In")]):_vm._e(),(_vm.profile)?_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""},domProps:{"textContent":_vm._s('Calendar')}},on),[_c('v-icon',[_vm._v("account")])],1)]}}],null,false,2552205940)},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-bullhorn")])],1),_c('v-list-item-title',[_vm._v("Announcements")])],1),_c('v-list-item',{attrs:{"to":"/calendar/"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-calendar")])],1),_c('v-list-item-title',[_vm._v("Calendar")])],1),_c('v-list-item',{attrs:{"target":"_blank","href":"https://calendar.google.com/calendar/ical/qahdd4ntr7vv59k4sp715bqq6k%40group.calendar.google.com/private-dc65e074a46a31d7495a8ecd87636653/basic.ics"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-google")])],1),_c('v-list-item-title',[_vm._v("Google Calendar")])],1)],1)],1):_vm._e(),(_vm.profile)?_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""},domProps:{"textContent":_vm._s('Homework')}},on),[_c('v-icon',[_vm._v("account")])],1)]}}],null,false,2379726376)},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/assignments/"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-format-list-numbered")])],1),_c('v-list-item-title',[_vm._v("Assignments")])],1),(_vm.profile.isStaff || _vm.profile.isSuperuser)?_c('v-list-item',{attrs:{"to":"/queue/"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-playlist-check")])],1),_c('v-list-item-title',[_vm._v("Queue")])],1):_vm._e(),_c('v-list-item',{attrs:{"to":"/files/"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-multiple")])],1),_c('v-list-item-title',[_vm._v("Files")])],1)],1)],1):_vm._e(),(_vm.profile)?_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""},domProps:{"textContent":_vm._s('Video')}},on),[_c('v-icon',[_vm._v("account")])],1)]}}],null,false,941503447)},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/watch-party/"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-party-popper")])],1),_c('v-list-item-title',[_vm._v("Watch Together")])],1),_c('v-list-item',{attrs:{"to":"/videos/"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-filmstrip")])],1),_c('v-list-item-title',[_vm._v("Previous recordings")])],1)],1)],1):_vm._e(),(_vm.profile)?_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""},domProps:{"textContent":_vm._s('Zoom')}},on),[_c('v-icon',[_vm._v("account")])],1)]}}],null,false,3841557105)},[_c('v-list',[_c('v-list-item',{attrs:{"href":_vm.saml}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-video")])],1),_c('v-list-item-title',[_vm._v("Log into Zoom")])],1),_c('v-divider'),_c('v-list-item',{attrs:{"target":"_blank","href":"https://rossprogram-org.zoom.us/j/97492237273"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-bank")])],1),_c('v-list-item-title',[_vm._v("Auditorium")])],1),(_vm.profile.isStaff)?_c('v-list-item',{attrs:{"target":"_blank","href":"https://rossprogram-org.zoom.us/j/94283884786"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-numeric-1-box")])],1),_c('v-list-item-title',[_vm._v("Counselor Grading Room")])],1):_vm._e(),_c('v-list-item',{attrs:{"target":"_blank","href":"https://rossprogram-org.zoom.us/j/96986251680"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-numeric-2-box")])],1),_c('v-list-item-title',[_vm._v("Camper Collaboration")])],1),_c('v-list-item',{attrs:{"target":"_blank","href":"https://rossprogram-org.zoom.us/j/97595923102"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-numeric-3-box")])],1),_c('v-list-item-title',[_vm._v("JC Room")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v("Room "),_vm._l((_vm.roomIds),function(id,index){return _c('v-btn',{key:index,attrs:{"target":"_blank","href":("https://rossprogram-org.zoom.us/j/" + id),"icon":""}},[_c('v-icon',[_vm._v("mdi-numeric-"+_vm._s(index + 4)+"-box")])],1)})],2)],1),_c('v-list-item',{attrs:{"to":"/rooms/"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home-city")])],1),_c('v-list-item-title',[_vm._v("All Rooms")])],1)],1)],1):_vm._e(),(_vm.profile)?_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""},domProps:{"textContent":_vm._s(_vm.profile.email)}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("account-circle")])],1)]}}],null,false,2031143623)},[_c('v-list',[_c('v-list-item',{attrs:{"to":("/users/" + (_vm.profile.id))}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-group")])],1),_c('v-list-item-title',[_vm._v("People")])],1),(_vm.profile.isStaff || _vm.profile.isSuperuser)?_c('v-list-item',{attrs:{"to":"/activity"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chart-pie")])],1),_c('v-list-item-title',[_vm._v("Recent activity")])],1):_vm._e(),(_vm.profile.isStaff || _vm.profile.isSuperuser)?_c('v-list-item',{attrs:{"to":"/reports/"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-check")])],1),_c('v-list-item-title',[_vm._v("Reports")])],1):_vm._e(),_c('v-list-item',{attrs:{"target":"_blank","href":"https://store.rossprogram.org/collections/all"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-tshirt-crew")])],1),_c('v-list-item-title',[_vm._v("Merchandise")])],1),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1):_vm._e()],1),_c('v-main',[_c('v-container',{staticClass:"pa-6",attrs:{"fluid":"","fill-height":""}},[_c('v-fade-transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1),(_vm.profile)?_c('chat'):_vm._e()],1)],1),_c('Snackbar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }